import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/request-demo-bg.svg";
import testimonialBannerBg from "../images/v2/testimonial-bg@2x.png";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import TestimonialCard from "../components/v2/TestimonialCard";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import img1 from "../images/v2/client-logos/singaporeFoundation.png";
import img2 from "../images/v2/client-logos/techDataLogo.png";
import img3 from "../images/v2/client-logos/msscLogo.png";
import img4 from "../images/v2/client-logos/sisLogo.png";
// import img5 from "../images/v2/client-logos/cuscapi-singapore-pte-ltd-pepper-cloud-customer.png";
import img5 from "../images/v2/client-logos/cuscapiLogo.png";
import img6 from "../images/v2/client-logos/techDataLogo.png";
import img7 from "../images/v2/client-logos/skypac-pepper-cloud-customer.png";
import img8 from "../images/v2/client-logos/supremeLogo.png";
import img9 from "../images/v2/client-logos/cashmartLogo.png";
import img10 from "../images/v2/client-logos/tofuLogo.png";
import img11 from "../images/v2/client-logos/aamsLogo.png";
import img12 from "../images/v2/client-logos/caffeineLogo.png";
import img13 from "../images/v2/client-logos/kossLogo.png";
import img14 from "../images/v2/client-logos/fireFlyLogo.png";
import img15 from "../images/v2/client-logos/mLogo.png";
import img16 from "../images/v2/client-logos/exoticLogo.png";
import img17 from "../images/v2/client-logos/incLogo.png";
import img18 from "../images/v2/client-logos/regentLogo.png";
import orangeArrow from "../images/v2/client-logos/orangeArrow.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  testimonialBanner: {
    background: `url(${testimonialBannerBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "10rem 0",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "5rem 0",
    },
  },
  nextImg: {
    /* Centering styles */
    // top: 50%,
    // left: 50%,
    // transform: translate(-50%, -50%);
    // alignItems: "center",
    // justifyContent : "center",

    /* Size styles */
    width: "300" /* Adjust width as needed */,
    height: "200" /* Adjust height as needed */,
  },
  togglerIcon:{
	width:'60px',
	transition:'0.5s ease',
  transform: 'rotate(180deg)',
	"&.active":{
		transform: 'rotate(0deg)'
	}
  }
}));

const TestimonialPage = () => {
  const width = useWidth();
  const classes = useStyles();

  const clientsPage1 = [
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+Singapore Maritime Foundation"
          height="108"
          src="../images/v2/testimonial/wilson-tan-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Singapore Maritime Foundation - Pepper Cloud customer"
          height="35"
          src={img1}
        />
      ),
      name: "Wilson Tan",
      position: "Senior Manager",
      company: "Singapore Maritime Foundation, Singapore",
      contentSmall: `“Pepper Cloud is a best  CRM tool to improve coordination among SMF departments and improve our database management.”`,
      content: (
        <React.Fragment>
          Pepper Cloud is a best CRM tool to improve coordination among SMF
          departments and improve our database management. The user-friendly
          interface, easy-to-use functions, and customisable features meant we
          could set up, integrate, and onboard the whole team on the cloud
          platform quickly. We appreciate the support and responsiveness of the
          Pepper Cloud team during the implementation process! I must say its a
          Singapore’s best CRM software specially for SMEs.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+TechData Corporation"
          height="108"
          src="../images/v2/testimonial/yoon-kam-fei-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img alt="Tech Data - Pepper Cloud customer" height="35" src={img2} />
      ),
      name: "Yoon Kam Fei",
      position: "Managing Director",
      company: "Tec D Distribution Sdn Bhd, Malaysia",
      content: (
        <React.Fragment>
          Team collaboration, accurate sales forecasting, and effective resource
          management are the challenges every organisation encounters. But
          having an efficient CRM tool like Pepper Cloud enhances productivity
          while assisting in optimising the sales process and managing the sales
          pipeline. Pepper Cloud CRM tool is the right mix of flexibility,
          affordability, and ease of use.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Mustard Seed System Corporation"
          height="108"
          src="../images/v2/testimonial/judith-adao-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Mustard seed system corporation - Pepper Cloud customer"
          height="35"
          src={img3}
        />
      ),
      name: "Judith M. Adao",
      position: "General Manager",
      company: "Mustard Seed Systems Corporation, Philippines",
      content: (
        <React.Fragment>
          Pepper Cloud is a simple and easy-to-use CRM tool and it gives all the
          information needed to manage the sales leads. The CRM also helps us to
          run data-driven marketing campaigns. Pepper Cloud's customer service
          and local support are incredible and helped us at every stage of the
          implementation. This CRM software is a must for every growing sales
          organisation.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + SiS Distribution (Thailand) PCL"
          height="108"
          src="../images/v2/testimonial/pantana-na-takuathung-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="SiS Distribution - Pepper cloud customer"
          height="35"
          src={img4}
        />
      ),
      name: "Pantana Na Takuathung",
      position: "General Manager",
      company: "SiS Distribution (Thailand) Public Co., Ltd",
      content: (
        <React.Fragment>
          Pepper Cloud is an excellent all-in-one CRM system to run multiple
          businesses across multiple domains. Through multiple sales pipelines,
          we track the business opportunities and the conversations between our
          team and our customers. Furthermore, this CRM software boosts the
          sales productivity of our team by helping us focus on core processes.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Cuscapi Singapore Pte. Ltd."
          height="108"
          // src="../images/v2/testimonial/anthony-gerald-peppercloud-customer.png"
          src="../images/v2/testimonial/anthony-gerald-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Cuscapi Singapore Pte. Ltd. - Pepper Cloud customer"
          height="35"
          src={img5}
        />
      ),
      name: "Anthony Gerald",
      position: "Group CEO",
      company: "Cuscapi Berhad, Malaysia",
      content: (
        <React.Fragment>
          The CRM Solution has a strong sales pipeline management tool that
          helps us to manage our business operations in 6 countries. It has a
          robust user management system with clearly defined access levels for
          data security and protection, which we needed. What I personally like
          the most in Pepper Cloud CRM is its service dedication and personal
          touch. There is a face behind the product to whom we can reach out to
          whenever there is a problem.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Tech Data Corporation Client"
          height="108"
          src="../images/v2/testimonial/sally-see-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img alt="Tech Data - Pepper Cloud customer" height="35" src={img6} />
      ),
      name: "Sally See",
      position: "Marketing Manager",
      company: "Tec D Distribution Sdn Bhd, Malaysia",
      content: (
        <React.Fragment>
          Pepper Cloud CRM has made real-time data accessible to everyone in the
          company. Its association feature allows linking the business and
          activity records, helping us to track past and present customer
          interactions and allowing a higher level opportunity conversion.
          Additionally, Pepper Cloud CRM takes our feedback seriously. We feel
          that we do have a say in the product roadmap.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Skypac Packaging Pte Ltd Client"
          height="108"
          src="../images/v2/testimonial/kelvin-peppercloud-customer.jpg"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Skypac Packaging - Pepper Cloud customer"
          height="35"
          src={img7}
        />
      ),
      name: "Kelvin Yeo",
      position: "Director",
      company: "Skypac Packaging Pte Ltd",
      content: (
        <React.Fragment>
          Pepper Cloud is easy to use, flexible, and scalable CRM software,
          which is helping us to streamline our sales process, cut down
          repetitive tasks and increase our work efficiency. The support and
          training offered by the team are impeccable. Pepper Cloud perfectly
          fits the requirements of Singapore SMEs. The solution is also a part
          of ‘SME Go Digital’ and pre-approved under the PSG grant that made us
          more confident on this solution.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + The supreme hr advisory"
          height="108"
          src="../images/v2/testimonial/joel-toh-peppercloud-customer.jpg"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="The supreme hr advisory - Pepper Cloud customer"
          height="35"
          src={img8}
        />
      ),
      name: "Joel Toh",
      position: "Director",
      company: "The Supreme HR Advisory",
      content: (
        <React.Fragment>
          Pepper Cloud is a sales-focused CRM that is helping our business to
          grow. The user interface is easy and our sales process is now more
          organised. Apart from the highly productive CRM features, we are happy
          with their exceptional support and relentless assistance. Also, Pepper
          Cloud rolls out new features as per the feedback and customer
          requirements. We are happy with the product and the people behind the
          product.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + The supreme hr advisory"
          height="108"
          src="../images/v2/testimonial/jimmy-lee-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img alt="Cashmart - Pepper Cloud customer" height="45" src={img9} />
      ),
      name: "Jimmy Lee",
      position: "Managing Director",
      company: "Cash Mart Pte. Ltd.",
      content: (
        <React.Fragment>
          Our growing sales team always faced issues with team collaboration,
          communication, and sales planning. After implementing Pepper Cloud
          CRM, most of the challenges were resolved in no time. The CRM system
          helped us manage our leads and sales pipelines and provided us with a
          unified platform to collaborate and communicate internally. We are
          extremely pleased with this solution and would definitely recommend
          it.
        </React.Fragment>
      ),
    },
  ];

  const clientsPage2 = [
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+Tofu Technologies Pte. Ltd."
          height="108"
          src="../images/v2/testimonial/Chiua-Ai-Zhen Dionis-pepper-cloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Tofu Technologies Pte. Ltd. - Pepper Cloud customer"
          height="45"
          src={img10}
        />
      ),
      name: "Chiua Ai Zhen Dionis",
      position: "Director",
      company: "Tofu Technologies Pte. Ltd.",
      contentSmall: `“Pepper Cloud is an excellent CRM solution that has helped us to level up our performance by efficiently managing our customer and sales pipeline.”`,
      content: (
        <React.Fragment>
          Pepper Cloud is an excellent CRM solution that has helped us to level
          up our performance by efficiently managing our customer and sales
          pipeline. Since the solution is on the cloud, we can access all
          customer information anytime, anywhere. We get full visibility into
          our sales pipeline and also a comprehensive report on our incoming
          leads. The system is user-friendly and a great CRM for SMEs seeking to
          embrace digitalisation.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud+AAMS"
          height="108"
          src="../images/v2/testimonial/Keith-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img alt="AAMS - Pepper Cloud customer" height="45" src={img11} />
      ),
      name: "Keith",
      position: "Manager",
      company: "AAMS",
      content: (
        <React.Fragment>
          After thoroughly evaluating a range of CRM options, our selection of
          Pepper Cloud CRM was primarily motivated by their in-depth grasp of
          our business operations. Right from the beginning, it was evident that
          Pepper Cloud CRM wasn't simply a software provider but a partner who
          understood the intricacies of our business operations. This level of
          comprehension was pivotal in aligning the CRM with our unique needs.
          Furthermore, the ability to customise the CRM system as per our needs
          proved highly valuable in efficiently managing our business.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud +  Caffeine Solutions Pvt. Ltd"
          height="108"
          src="../images/v2/testimonial/Ben Fones-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Caffeine Solutions Pvt. Ltd - Pepper Cloud customer"
          height="45"
          src={img12}
        />
      ),
      name: "Ben Fones",
      position: "Director",
      company: "Caffeine Solutions Pvt. Ltd",
      content: (
        <React.Fragment>
          This CRM solution is perfect for SMEs like ours. Our sales team has
          become more efficient and proactive. With this CRM, our team stays
          connected and can easily track sales activities in an organized
          manner. It's a comprehensive sales CRM with excellent features. The
          after-sales support is pleasantly very responsive and supportive. I
          highly recommend this CRM software for SMEs.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + KCSS Pest Management Pte. Ltd"
          height="108"
          src="../images/v2/testimonial/SivaKumar Subramani-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="KCSS Pest Management Pte. Ltd - Pepper cloud customer"
          height="45"
          src={img13}
        />
      ),
      name: (
        <span>
          SivaKumar
          <br />
          Subramani
        </span>
      ),
      position: "Director",
      company: "KCSS Pest Management Pte. Ltd",
      content: (
        <React.Fragment>
          Pepper Cloud CRM helps us manage our sales efficiently. It was simple
          to set up, and the easy-to-use interface allowed us to quickly focus
          on sales by planning our day-to-day sales activities. Its multiple
          message channel integration helps us to manage sales on the go. Their
          sales tracking & forecasting feature is very helpful for our team.
          This makes all the difference for a growing sales organisation like
          ours. Thank you, Pepper Cloud!
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Firefly Photography Pte. Ltd."
          height="108"
          src="../images/v2/testimonial/Benny-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Firefly Photography Pte. Ltd. - Pepper Cloud customer"
          height="55"
          src={img14}
        />
      ),
      name: "Benny",
      position: "Creative Director",
      company: "Firefly Photography Pte. Ltd.",
      content: (
        <React.Fragment>
          Incorporating Pepper Cloud CRM for our business was smooth and
          hassle-free. The onboarding process was well-guided, and the training
          resources provided by their team facilitated a smooth transition for
          our employees to adapt to the new system. In a remarkably short
          period, we became fully accustomed to the CRM system and started
          experiencing its benefits. Thanks to Pepper Cloud.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud +  Maven Brokers Pte. Ltd."
          height="108"
          src="../images/v2/testimonial/Sky-Tan-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Maven Brokers Pte. Ltd. - Pepper Cloud customer"
          height="55"
          src={img15}
        />
      ),
      name: "Sky Tan",
      position: "Marketing Manager",
      company: "Maven Brokers Pte. Ltd.",
      content: (
        <React.Fragment>
          Being an SME, we needed reliable support after implementing the
          product. The Pepper Cloud team has been incredibly helpful, responding
          promptly through WhatsApp, email, and phone calls. They actively
          listen to our feedback and address our concerns. We highly recommend
          them to fellow local SMEs.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Exotic Office Solution."
          height="108"
          src="../images/v2/testimonial/Jaden-Tan-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Exotic Office Solution - Pepper Cloud customer"
          height="45"
          src={img16}
        />
      ),
      name: "Jaden Tan",
      position: "Director",
      company: "Exotic Office Solution",
      content: (
        <React.Fragment>
          Pepper Cloud, a user-friendly CRM, is seamlessly integrated into our
          workflow, providing robust lead management, efficient contact
          organisation, and valuable analytics. This streamlined our daily
          operations, enabling more time for deal closure and data-driven
          decision-making. Their responsive customer support and tangible ROI,
          including increased sales, satisfaction, and cost savings, have truly
          transformed our operations.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + 707-inc Great Excursion Travel Pte Ltd"
          height="108"
          src="../images/v2/testimonial/Joshua Kiong-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="707-inc Great Excursion Travel Pte Ltd - Pepper Cloud customer"
          height="60"
          src={img17}
        />
      ),
      name: "Joshua Kiong",
      position: "Manager (Advisor)",
      company: "707-inc Great Excursion Travel Pte Ltd",
      content: (
        <React.Fragment>
          Implementing Pepper Cloud CRM was seamless with their helpful
          onboarding and training resources. It's our perfect CRM solution,
          meeting all our needs (such as tracking sales, automating workflows,
          and integrating with various platforms) and offering proactive
          round-the-clock support. The WhatsApp CRM also helped us streamline
          customer conversations and enable seamless interaction tracking,
          ultimately leading to increased productivity and efficiency.
        </React.Fragment>
      ),
    },
    {
      image: (
        <StaticImage
          alt="Pepper Cloud + Regent Maid Agency Pte Ltd"
          height="108"
          src="../images/v2/testimonial/Stephanie-Chew-peppercloud-customer.png"
          width="113"
        />
      ),
      clientLogo: (
        <img
          alt="Regent Maid Agency Pte Ltd - Pepper Cloud customer"
          height="60"
          src={img18}
        />
      ),
      name: "Stephanie Chew",
      position: "Managing Director",
      company: "Regent Maid Agency Pte Ltd",
      content: (
        <React.Fragment>
          We embarked on a digital journey with Pepper Cloud and it has really
          worked for us. The CRM software has helped us to organise business and
          let us focus on improving our customer outreach and relationships.
          Now, we don’t have to spend time manually working in Excel sheets and
          creating reports, etc. The company support is perfect and they are
          always approachable on WhatsApp/Email/Phone.
        </React.Fragment>
      ),
    },
  ];

  const combinedClients = [...clientsPage1, ...clientsPage2];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextClick = () => {
    if (currentIndex + 9 < combinedClients.length) {
      setCurrentIndex(currentIndex + 9);
    }
  };

  const handlePreviousClick = () => {
    if (currentIndex - 9 >= 0) {
      setCurrentIndex(currentIndex - 9);
    }
  };

  return (
    <React.Fragment>
      <SEO
        canonical="/crm-testimonials"
        description="Read the customers' experiences about Singapore's #1 sales CRM, Pepper Cloud.
        Pepper Cloud is an excellent all-in-one CRM tool to run multiple businesses across multiple domains."
        keywords="customer experience, sales process, CRM tool"
        ogData={{
          "og:image": [
            "/meta/og/1X1/testimonials.png",
            "/meta/og/1024X512/testimonials.png",
            "/meta/og/1200X630/testimonials.png",
          ],
        }}
        pathname="/crm-testimonials"
        title="Customer testimonials | Pepper Cloud CRM"
      />
      <Box className={classes.testimonialBanner}>
        <Container>
          <Box textAlign={{ sm: "center", xs: "center" }}>
            <HeaderTypography component="h1" fontWeight={700} m={0} pb={4}>
              Our happy customers share their experiences
            </HeaderTypography>
            <ParagraphTypography m={0}>
              Read our customer experiences. Discover how small and medium
              businesses across the globe found solutions for their different
              business needs <br /> with Pepper Cloud CRM software.
            </ParagraphTypography>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box mb={6}>
          <Grid container spacing={0.5}>
            {combinedClients
              .slice(currentIndex, currentIndex + 9)
              .map((client) => (
                <Grid item key={client.name} lg={4} md={6} sm={6} xs={12}>
                  <TestimonialCard {...client} />
                </Grid>
              ))}
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
            <div
              onClick={currentIndex + 9 < combinedClients.length ? handleNextClick : handlePreviousClick}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
             <img src={orangeArrow} className={clsx(classes.togglerIcon,currentIndex + 9 < combinedClients.length ? '' : 'active')}/>
            </div>
        </div>
      </Container>

      <Box className={classes.requestDemoRoot} py={12} textAlign="center">
        <Box alignItems="center" display="flex" justifyContent="center">
          <HeaderTypography
            color="common.white"
            fontSize={40}
            my={0}
            textAlign="center"
          >
            Join our happy customers and
            <br /> increase your sales revenue
          </HeaderTypography>
        </Box>
        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact?utm_source=Testimonials&utm_medium=Book+a+free+demo"
            variant="contained"
          >
            Book a free demo
          </WhiteButton>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default TestimonialPage;
